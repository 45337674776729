<template>
  <v-row dense align="center">
    <v-col cols="auto">
      <v-btn
        color="primary"
        dark
        elevation="1"
        small
        :loading="uploadingCsv"
        @click="handleUploadCsvDropdownClick(dataTypeProp)"
      >
        {{$_strings.selectioninfo.button.UPLOAD_CSV}}
      </v-btn>
      <input
        class="d-none"
        id="csv-upload"
        :disabled="uploadingCsv"
        ref="fileInput"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="uploadCsv"
      />
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        dark
        elevation="1"
        small
        :loading="downloadingCsv"
        @click="downloadCsvTemplate(dataTypeProp)"
      >
        {{$_strings.selectioninfo.button.TEMPLATE_CSV}}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <UploadedCsvStatusDialog :importType="importTypeProp" :dataType="dataTypeProp" />
    </v-col>
  </v-row>
</template>

<script>

const UploadedCsvStatusDialog = () => import('./UploadedCsvStatusDialog');
const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  name: 'MasterDataCsvUpload',
  data() {
    return {
      file: null,
      uploadingCsv: false,
      dataType: null,
      downloadingCsv: false,
    };
  },
  components: {
    UploadedCsvStatusDialog,
  },
  props: {
    dataTypeProp: String,
    uploadCsvProp: Function,
    downloadTemplateCsvProp: Function,
    importTypeProp: String,
  },

  computed: {
    dataTypeOptions() {
      return this.$_strings.csvUpload.csvDataTypeOptions.filter((i) => i.databaseType === this.dataTypeProp);
    },
  },
  methods: {
    async handleUploadCsvDropdownClick(command) {
      await this.$refs.fileInput.click();
      this.dataType = command;
    },
    handleDownloadCsvDropdownClick(command) {
      this.downloadCsvTemplate(command);
    },
    downloadCsvTemplate(type) {
      this.downloadingCsv = true;
      this.downloadTemplateCsvProp(type)
        .then((result) => {
          const { column, filename } = result.data;
          exportCSVFile(null, [[...column]], filename);
        }).finally(() => {
          this.downloadingCsv = false;
        });
    },
    fetchList() {
      // ADD SET TIMEOUT FOR WAITING DATA ALREADY EXISTS IN DATABASES
      this.$root.$loading.show();
      setTimeout(() => {
        this.$emit('fetchList');
        this.$root.$loading.hide();
      }, 1000);
    },
    uploadCsv() {
      try {
        this.uploadingCsv = true;
        const formData = new FormData();
        formData.append('file', this.$refs.fileInput.files[0]);
        formData.append('type', this.dataType || this.dataTypeProp);
        this.uploadCsvProp(formData)
          .then(() => {
            this.$dialog.notify.success(this.$_strings.csvUpload.uploadCsvSuccessMessage);
            this.fetchList();
            const input = this.$refs.fileInput;
            input.type = 'text';
            input.type = 'file';
          }).finally(() => {
            this.uploadingCsv = false;
          });
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
  },
};
</script>
